<template>
    <!-- 财务报表 —— 巡检人员收费报表 -->
    <div class="box-card" style="text-align:left;">
      <!-- 主要内容 -->
      <div>
        <div class="dialog_content">
          <div id="pei_box" style="width: 50%;height: 330px;"></div>
          <el-form class="show_form" ref="form" :model="detailPage" label-width="120px">
            <el-form-item label="停车场：">{{detailPage.parking_name}}</el-form-item>
            <el-form-item label="时间：">{{$moment(detailPage.date).format('YYYY-MM-DD')}}</el-form-item>
            <el-form-item label="收费人员：">{{detailPage.user_name}}</el-form-item>
            <el-form-item label="扫码收费：">{{'￥'+ detailPage.scan_code.toFixed(2)}}</el-form-item>
            <el-form-item label="收款码收费：">{{'￥'+ detailPage.collection_code.toFixed(2)}}</el-form-item>
            <el-form-item label="现金收费：">{{'￥'+ detailPage.cash.toFixed(2)}}</el-form-item>
            <el-form-item label="收费总计：">{{'￥'+ detailPage.sum_money.toFixed(2)}}</el-form-item>
          </el-form>
        </div>
        <!-- 查询表单 -->
        <div class="top_content">
          <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" @submit.native.prevent>
            <el-form-item label="收费方式：">
              <el-select popper-class="my-select" v-model="queryForm.payee_type" filterable clearable default-first-option placeholder="请选择收费方式" @change="onSubmit()">
                <el-option v-for="item in getOptions('PayeeType', true)" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="支付方式：">
              <el-select popper-class="my-select" v-model="queryForm.payment_method" filterable clearable default-first-option placeholder="请选择支付方式" @change="onSubmit()">
                <el-option v-for="item in getOptions('PaymentMethod', true)" :key="item.value" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
            </el-form-item>
          </el-form>
          <div class="right">
            总计：<span class="right_totle">￥{{total_amount.toFixed(2)}}</span>
          </div>
        </div>
        <!-- 表格 -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center" tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column prop="order_no" label="订单号"> </el-table-column>
          <el-table-column prop="plate_number" label="车牌号"></el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column label="停车时长">
            <template slot-scope="scope">
              <span>{{ getTimeLength(scope.row.parking_time_length)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="金额">
            <template slot-scope="scope">
              <span>￥{{scope.row.final_cost.toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="收费方式">
            <template slot-scope="scope">
              <span>{{getWayName("PayeeType", scope.row.payee_type)}}收费</span>
            </template>
          </el-table-column>
          <el-table-column label="支付方式">
            <template slot-scope="scope">
              <span>{{scope.row.payment_method === 0 ? '未支付':getWayName("PaymentMethod", scope.row.payment_method)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="收费时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.payment_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </div>
  </template>

<script>
import { getInspectorChargeDetail } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import * as echarts from 'echarts'
export default {
  props: ['id', 'date', 'parking_name'],
  data () {
    return {
      // 查询表单
      queryForm: {
        inspector_id: '',
        date: '',
        parking_name: '',
        payment_method: null, // 支付方式 0:全部 微信=1, 支付宝=2, 现金=3,ETC = 4
        payee_type: null, // 收款类型 0:全部 收款码=1,扫码=2,现金=3
        PageIndex: 1,
        PageSize: 10
      },
      total: 0,
      total_amount: 0,
      // 表格数据
      tableData: [],
      // 详情
      detailPage: {}
    }
  },
  created () {
    this.queryForm.inspector_id = this.id
    this.queryForm.date = this.date
    this.queryForm.parking_name = this.parking_name
    this.fnGetInspectorChargeDetail()
  },
  computed: {
    ...mapState(['parkingList']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    async fnGetInspectorChargeDetail () {
      const res = await getInspectorChargeDetail({
        inspector_id: this.queryForm.inspector_id,
        date: this.queryForm.date,
        parking_name: this.queryForm.parking_name,
        // 支付方式 0:全部 微信=1, 支付宝=2, 现金=3,ETC = 4
        payment_method: this.queryForm.payment_method ? this.queryForm.payment_method : 0,
        // 收款类型 0:全部 收款码=1,扫码=2,现金=3
        payee_type: this.queryForm.payee_type ? this.queryForm.payee_type : 0,
        PageIndex: this.queryForm.PageIndex,
        PageSize: this.queryForm.PageSize
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.detailPage = res.Data
        this.tableData = res.Data.orders
        this.total = res.Data.order_count
        this.total_amount = res.Data.sum_money
      } else {
        this.detailPage = {}
        this.tableData = []
        this.total = 0
        this.total_amount = 0
      }
      this.$nextTick(() => {
        this.initEcharts()
      })
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetInspectorChargeDetail()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectorChargeDetail()
    },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectorChargeDetail()
      // console.log(`当前页: ${val}`);
    },
    defaultForm () {
      this.$emit('closeDialog', false)
    },
    // 饼状图
    initEcharts () {
      var dom = document.getElementById('pei_box')
      if (!dom) return
      var total = 0
      var seriesData = [
        { value: this.detailPage.scan_code, name: '扫码收费', itemStyle: { color: '#5470c6' } },
        { value: this.detailPage.collection_code, name: '收款码收费', itemStyle: { color: '#91cc75' } },
        { value: this.detailPage.cash, name: '现金收费', itemStyle: { color: '#fac858' } }
      ]
      total = this.detailPage.scan_code + this.detailPage.collection_code + this.detailPage.cash
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(dom)
      console.log(myChart)
      // 绘制图表
      var colorList = ['#33c0d5', '#1acd90', '#e39f28']
      var option
      option = {
        color: colorList,
        title: [
          {
            // 第一个圆环标题
            text: total.toFixed(2) + ' 元', // 主标题
            textStyle: {
              // 主标题样式
              color: '#01a6fb',
              fontSize: 16,
              fontWeight: '700'
            },
            left: '49%', // 定位到适合的位置
            top: '38%', // 定位到适合的位置
            subtext: '总缴费', // 副标题
            subtextStyle: {
              // 副标题样式
              color: '#666666',
              fontSize: 14
            },
            textAlign: 'center' // 主、副标题水平居中显示
          }
        ],
        legend: {
          // 强制设置图形为圆。
          icon: 'circle',
          orient: 'horizontal',
          left: 'center',
          bottom: '5%',
          textStyle: {
            color: '#666666',
            fontSize: 14,
            align: 'left',
            verticalAlign: 'middle'
          },
          data: seriesData
        },
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var tip = ''
            if (params.seriesIndex === 0) {
              tip = `<div style="text-align: left;">${params.name}：<span style="font-weight: 700;color:${params.color};">缴费${params.value}元</span></div>
                <div style="text-align: left;">占比：<span style="font-weight: 700;color:${params.color};">${params.percent}%</span></div>`
            } else {
              tip = ''
            }
            return tip
          }
        },
        series: [
          // 环形展示层
          {
            type: 'pie',
            color: colorList,
            radius: ['40%', '55%'],
            center: ['50%', '45%'],
            label: {
              show: false
            },
            data: seriesData
          },
          // 内部设置
          {
            type: 'pie',
            radius: ['30%', '0%'],
            center: ['50%', '45%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 10,
                shadowColor: 'rgba(1, 1, 1, 0.1)',
                color: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          },
          // 边框
          {
            type: 'pie',
            radius: ['70%', '69%'],
            // 饼图位置参数
            center: ['50%', '45%'],
            hoverAnimation: false,
            clockWise: false,
            itemStyle: {
              normal: {
                shadowBlur: 20,
                shadowColor: 'rgba(1, 166, 251, 0.3)',
                borderColor: '#fff'
              }
            },
            label: {
              show: false
            },
            data: [100]
          }
        ]
      }
      myChart.clear()
      myChart.setOption(option)
    }
  }
}
</script>

  <style scoped lang="less">
  .right_totle{
    font-size:22px;
    font-weight: 600;
    color:#000;
  }
  .top_content {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }
  .dialog_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .show_form {
      width: 50%;
      .el-form-item {
        margin-bottom: 0;
      }
    }
  }
  .tablestyle {
    /deep/ .el-table__body-wrapper {
      max-height: 200px;
    }
  }
  .box-card {
    position: relative;
    padding-bottom: 30px;

    .checkButton {
        position: absolute;
        right: 50px;
    }

    .checkButton2 {
        position: absolute;
        right: 150px;
    }

    .checkButton3 {
        position: absolute;
        right: 250px;
    }
}
  </style>

<template>
  <!-- 财务报表 —— 巡检人员收费报表 -->
  <div class="box-card" style="text-align:left;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
      @submit.native.prevent>
      <el-form-item label="时间：">
        <el-date-picker v-model="queryForm.create_time" :clearable="false" type="date" value-format="yyyy-MM-dd"
          @change="onSubmit()" :editable="false" placeholder="请选择时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="停车场：">
        <el-select popper-class="my-select" v-model="queryForm.parking_name" filterable clearable default-first-option
          placeholder="请选择停车场" @change="onSubmit">
          <el-option v-for="item in parkingList" :key="item.parking_id" :label="item.parking_name"
            :value="item.parking_name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="收费人员：">
        <el-input v-model="queryForm.user_name" @keyup.enter.native="onSubmit()" placeholder="请输入收费人员"
          @input="(e) => (queryForm.account = validSpace(e))"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card>
      <template slot="heard-left">
        <leCount :count="total_amount"></leCount>
      </template>
      <template slot="heard-right">
        <div class="right">
          <!-- <el-button  size="small" type="danger"  @click="batchDel" >删除</lbButton> -->
          <lbButton type="warning" icon="daochu" @click="exportFile"
            v-if="isShowBtn('527348eb9564496e962031f2') || isShowBtn('9c297d6503024f69852847b5')">导出</lbButton>
        </div>
      </template>
      <div>
        <!-- 主要内容 -->
        <!-- 表格 -->
         <!-- 使用插件生产表格 -->
         <!-- <avue-crud :data="tableData" :option="option" :page.sync="page"
          @size-change="sizeChange" @current-change="currentChange">
           <template slot-scope="{row}" slot="date">
            {{ $moment(row.date).format('YYYY-MM-DD') }}
          </template>
           <template slot-scope="{row}" slot="scan_code">
            ￥{{ row.scan_code.toFixed(2) }}
          </template>
           <template slot-scope="{row}" slot="collection_code">
            ￥{{ row.collection_code.toFixed(2) }}
          </template>
            <template slot-scope="{row}" slot="cash">
              ￥{{ row.cash.toFixed(2) }}
          </template>
          <template slot-scope="{row}" slot="sum_money">
            ￥{{row.sum_money.toFixed(2) }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetail(row)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"
          tooltip-effect="dark" style="width: 100%" border stripe>
          <el-table-column label="时间">
            <template slot-scope="scope">
              {{ $moment(scope.row.date).format('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column prop="parking_name" label="停车场"></el-table-column>
          <el-table-column prop="user_name" label="收费人员"></el-table-column>
          <el-table-column label="扫码收费">
            <template slot-scope="scope">
              ￥{{ scope.row.scan_code.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="收款码收费">
            <template slot-scope="scope">
              ￥{{ scope.row.collection_code.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="现金收费">
            <template slot-scope="scope">
              ￥{{ scope.row.cash.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="收费总计">
            <template slot-scope="scope">
              ￥{{ scope.row.sum_money.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookDetail(scope.row)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <!-- 页码 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" >
        </el-pagination>
      </div>
    </le-card>
    <div>
    </div>
    <!-- 查看巡检人员收费报表 -->
    <lebo-dialog :isShow="showLookInspectorReportforms" width="70%" title="查看巡检人员收费报表"
      :footerSlot="true" @close="showLookInspectorReportforms = false" closeOnClickModal>
      <lookInsectorReportforms v-if="showLookInspectorReportforms" :id="dialogInspectorReportformsId" :date='dialogDate'
        :parking_name="dialogParkingName" @closeDialog="closeInsectorReportforms"></lookInsectorReportforms>
    </lebo-dialog>
  </div>
</template>

<script>
import { getInspectorChargeList } from '@/api/financialStatement'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import * as echarts from 'echarts'
import lookInsectorReportforms from './components/lookInsectorReportforms/index.vue'
export default {
  components: { lookInsectorReportforms },
  data () {
    return {
      // 查询表单
      queryForm: {
        parking_name: '',
        user_name: '',
        create_time: '',
        PageIndex: 1,
        PageSize: 10
      },
      total: 0,
      total_amount: 0,
      // 表格数据
      tableData: [],
      dialogParkingName: '',
      dialogDate: '',
      dialogInspectorReportformsId: '',
      showLookInspectorReportforms: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '时间',
            prop: 'date',
            slot: true
          }, {
            label: '停车场',
            prop: 'parking_name'
          }, {
            label: '收费人员',
            prop: 'user_name'
          }, {
            label: '扫码收费',
            prop: 'scan_code',
            slot: true
          }, {
            label: '收款码收费',
            prop: 'collection_code',
            slot: true
          }, {
            label: '现金收费',
            prop: 'cash',
            slot: true
          }, {
            label: '收费总计',
            prop: 'sum_money',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.queryForm.create_time = this.queryForm.create_time === '' ? this.$moment(new Date()).format('YYYY-MM-DD') : this.queryForm.create_time
    this.fnGetInspectorCheckList()
  },
  computed: {
    ...mapState(['parkingList', 'queryObj', 'queryStatus']),
    ...mapGetters(['getOptions', 'getWayName'])
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    async fnGetInspectorCheckList () {
      const res = await getInspectorChargeList({
        parking_name: this.queryForm.parking_name,
        user_name: this.queryForm.user_name,
        create_time: this.queryForm.create_time,
        PageIndex: this.queryForm.PageIndex,
        PageSize: this.queryForm.PageSize
      })
      console.log(res)
      if (res && res.Code === 200) {
        this.total = res.Data.TotalCount
        this.tableData = res.Data.DataList
        this.total_amount = res.Data.money
      } else {
        this.total = 0
        this.tableData = []
        this.total_amount = 0
      }
      // console.log(this.tableData);
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      // console.log(this.queryForm);
      this.fnGetInspectorCheckList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetInspectorCheckList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetInspectorCheckList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetInspectorCheckList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      // console.log(`当前页: ${val}`);
    },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetInspectorCheckList()
    // },
    lookDetail (row) {
      console.log(row)
      this.dialogInspectorReportformsId = row.inspector_id
      this.dialogDate = row.date
      this.dialogParkingName = row.parking_name
      this.showLookInspectorReportforms = true
    },
    async exportFile () {
      const res = await getInspectorChargeList({
        parking_name: this.queryForm.parking_name,
        user_name: this.queryForm.user_name,
        create_time: this.queryForm.create_time !== '' ? this.queryForm.create_time : '0001-01-01',
        PageIndex: 0,
        PageSize: 0
      })
      // console.log(res);
      const resultList = res.Data.DataList
      if (resultList.length <= 0) {
        this.$msg.warning('暂无数据！')
      } else {
        // 设置当前日期
        var name = this.$moment(new Date()).format('YYYY-MM-DD')
        // console.log(name);
        import('@/utils/Export2Excel').then((excel) => {
          const tHeader = [
            '时间',
            '停车场',
            '收费人员',
            '扫码收费',
            '收款码收费',
            '现金收费',
            '收费总计'
          ]
          const filterVal = [
            'date',
            'parking_name',
            'user_name',
            'scan_code',
            'collection_code',
            'cash',
            'sum_money'
          ]
          const list = resultList
          const data = this.formatJson(filterVal, list)
          // console.log(data);
          excel.export_json_to_excel({
            header: tHeader, //  必填   导出数据的表头
            data, //  必填   导出具体数据
            filename: '巡检人员收费报表' + this.queryForm.create_time, //  非必填   导出文件名
            autoWidth: true, //  非必填   单元格是否自动适应宽度
            bookType: 'xlsx' //  非必填   导出文件类型
          })
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        var map = {}
        this.$set(map, j, v[j])
        return this.formatter(map, { property: j })
        // 为formatter专门为了码表渲染的组件
      })
      )
    },
    // 数据格式化
    formatter (row, column) {
      // 日期处理
      if (column.property === 'date') {
        return this.$moment(row.date).format('YYYY-MM-DD')
      } else if (column.property === 'scan_code' || column.property === 'collection_code' || column.property === 'cash' || column.property === 'sum_money') {
        return row[column.property] = '￥' + row[column.property]
      } else {
        return row[column.property]
      }
    },
    closeInsectorReportforms () {
      this.showLookInspectorReportforms = false
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
  margin-top: 20px;
}

.top_content {
  display: flex;
  justify-content: space-between;

  .right_box {
    width: 25%;
    text-align: right;

    span {
      font-size: 18px;
      font-weight: 600;
      color: #000;
    }
  }
}

.dialog_content {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .show_form {
    .el-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
